<template>
  <v-footer
    id="core-footer"
    height="30"
    xtile
    inset
    app
    elevation="2"
  >
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links"
        >{{ link.name }}</a>
      </span>
    </div>
    <v-spacer />
    <span class="copyright text-caption grey--text">
      &copy; {{ this.$moment().year() }} Arkstone Medical Solutions, LLC
    </span>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        // { name: 'Support', Link: '/support' }
      ],
    }),
  }
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
